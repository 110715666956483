import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useStockrecords } from './getStockForumData';
export const StockPerformanceContent = memo(function StockPerformanceContent() {
    const data = useStockrecords('Ronaldo').data?.data.map(s => s.attributes);
    return (<styleds.container>
      <styleds.header>
        <styleds.iconProduct src='stockforum/profit-icon.png'></styleds.iconProduct>
        <span>會員親代股票操作回顧</span>
      </styleds.header>
      <styleds.body>
        {data?.map((s, index) => {
            return (<SymbolPerformance key={index} rank={index + 1} config={s}/>);
        })}
      </styleds.body>
    </styleds.container>);
});
const SymbolPerformance = memo(function SymbolPerformance(props) {
    const rankicon = props.rank <= 3 ? 'stockforum/ranking' + props.rank + '-icon.png' : 'stockforum/win-icon.png';
    const config = props.config;
    const entryType = config?.entryType === 'B' ? '買進' : '賣出';
    const exitType = config?.exitType === 'B' ? '買進' : '賣出';
    const profit = (config?.exitPrice - config?.entryPrice) * 1000;
    return (<div css={css `
          ${fill_horizontal_all_center};
          display: grid;
          grid-template-columns: 8% 20% 10% 16% 10% 16% 20%;
          & > span {
            ${fill_horizontal_cross_center};
          }
        `}>
        <styleds.iconranking src={rankicon}/>
        <span>
          {config?.symbol}
          <SymbolName symbol={config?.symbol}/>
        </span>
        <span>{config?.entryDate.slice(-5)}</span>
        <span>
          {entryType}:{config?.entryPrice}
        </span>
        <span>{config?.exitDate.slice(-5)}</span>
        <span>
          {exitType}:{config?.exitPrice}
        </span>
        <span>獲利 {profit}</span>
      </div>);
});
// container
// header
// iconProduct
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    height: auto;
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 40px calc(100% - 112px) 72px;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    gap: 8px;
    padding: 8px;
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
    margin-left: -4px;
  `,
    iconranking: styled.img `
    width: 32px;
    margin-left: -4px;
  `,
};
