import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useFuturesrecords } from './getStockForumData';
export const FuturesPerformanceContent = memo(function FuturesPerformanceContent() {
    const data = useFuturesrecords('Ronaldo').data?.data?.map(s => s.attributes);
    return (<styleds.container>
      <styleds.header>
        <styleds.iconProduct src='stockforum/profit2-icon.png'></styleds.iconProduct>
        <span>期貨操作回顧</span>
      </styleds.header>
      <styleds.body>
        {data?.map((s, index) => {
            return (<SymbolPerformance key={index} rank={index + 1} config={s}/>);
        })}
      </styleds.body>
    </styleds.container>);
});
const SymbolPerformance = memo(function SymbolPerformance(props) {
    const rankicon = 'stockforum/ranking' + props.rank + '-icon.png';
    const config = props.config;
    const profit = config.exitPrice - config.entryPrice;
    return (<div css={css `
          ${fill_horizontal_all_center};
          display: grid;
          grid-template-columns: 20% 20% 20% 20% 20%;
          & > span {
            ${fill_horizontal_all_center};
          }
        `}>
        <span>
          {config.symbol}
          <SymbolName symbol={config.symbol}/>
        </span>
        <span>{config.date}</span>
        <span>{config.entryPrice}</span>
        <span>{config.exitPrice}</span>
        <span>獲利 {profit} 點</span>
      </div>);
});
// container
// header
// iconProduct
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    height: auto;
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 40px calc(100% - 112px) 72px;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    gap: 8px;
    padding: 8px;
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
    margin-left: -4px;
  `,
    iconranking: styled.img `
    width: 32px;
    margin-left: -4px;
  `,
};
