import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { ProductContent } from './component/ProductContent';
import { StockPerformanceContent } from './component/StockPerformanceContent';
import { VideoContent } from './component/VideoContent';
import { AnalystInfoContent } from './component/AnalystInfoContent';
import { FuturesPerformanceContent } from './component/FuturesPerformanceContent';
const videoConfig = [
    {
        title: '不是金融科系不配做交易夢？零背景轉職會遇到什麼困難？🤫現職交易員告訴你！',
        imgSrc: 'https://img.youtube.com/vi/PZSfKoHHsu0/0.jpg',
        text: '零背景也能轉職當全職交易員嗎？ 是否需要具備什麼特質交易員的生活又跟想像中的有什麼不一樣呢？ 今天我們請到三位現職交易員，從大學生、護理師、消防員，超大跨度的轉職生活一次問好問滿！ （雖然長慶根本不算是轉職XD）',
    },
    {
        title: '【盤前規劃】黃金規劃－深入繪製實用黃金策略📈快速捕捉交易突破點',
        imgSrc: 'https://img.youtube.com/vi/re8Q04oOjaQ/0.jpg',
        text: '上次的日經規劃不知道大家有沒有把握住呢？如果有看影片的朋友，相信吃到幾餐薯條加大絕對是沒問題的🤣沒跟上也不哭，今天老大又帶來新的規劃啦～有追蹤新聞的應該都知道，近期有國家挖出了大量黃金，快來看看這波有什麼可以跟上的策略規劃吧🙌',
    },
];
export const StockForum_Main = memo(function StockForum_Main() {
    return (<styleds.container>
      <styleds.grid>
        <LeftContent />
        <RightContent />
      </styleds.grid>
    </styleds.container>);
});
const RightContent = memo(function RightContent() {
    return (<styledr.container>
      <ProductContent />
      <StockPerformanceContent />
      <VideoContent videoConfig={videoConfig} channelName='期天投顧財金頻道-理財最錢線'/>
    </styledr.container>);
});
const LeftContent = memo(function LeftContent() {
    return (<styledr.container>
      <AnalystInfoContent />
      <FuturesPerformanceContent />
      <StockPerformanceContent />
    </styledr.container>);
});
const styledr = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 8px;
    padding: 8px;
  `,
};
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #f8f8f8;

    //需要做螢幕寬度判斷padding級距
    padding: 0 120px;
  `,
    grid: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-columns: 50% 50%;
    background-color: #f0f0f0;
  `,
};
