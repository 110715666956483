import styled from '@emotion/styled';
import { memo } from 'react';
import { fill, fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useAnalysts, useIntroduction } from './getStockForumData';
import { css } from '@emotion/react';
const emptyAnalysts = {
    name: '',
    channelName: '',
    programName: '',
    facebookLink: '',
    lineLink: '',
    instagramLink: '',
    telegramLink: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
};
const emptyIntroductions = {
    title: '',
    content: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
};
export const AnalystInfoContent = memo(function AnalystInfoContent() {
    const dataIntroductions = useIntroduction('Ronaldo')?.data?.data?.map(s => s?.attributes) ?? [
        emptyIntroductions,
    ];
    const dataAnalysts = useAnalysts('Ronaldo')?.data?.data?.map(s => s.attributes) ?? [emptyAnalysts];
    const Item = memo(function Item(props) {
        return (<div css={css `
          width: 100%;
          padding: 4px;
        `}>
        <styleds.infoHeader>
          <styleds.starIcon src='stockforum/star-icon.png'></styleds.starIcon>
          {props.data.title}
        </styleds.infoHeader>
        <styleds.infobody>{props.data.content} </styleds.infobody>
      </div>);
    });
    return (<styleds.container>
      <styleds.header>
        <styleds.iconProduct src='stockforum/analyst-icon.png'></styleds.iconProduct>
        <span>分析師介紹</span>
      </styleds.header>
      <styleds.body>
        <styleds.content>
          <styleds.infoImg>
            <styleds.personImg src='stockforum/man.png'></styleds.personImg>
            <span>分析師: {dataAnalysts?.map(s => s.name)}</span>
            <span>節目名稱: {dataAnalysts?.map(s => s.channelName)}</span>
            <styleds.socialAppIconContent>
              <img src='https://www.asia-rich.com.tw/images/analyst/icon03.svg'></img>
              <img src='https://www.asia-rich.com.tw/images/analyst/icon04.svg'></img>
              <img src='https://www.asia-rich.com.tw/images/analyst/icon01.svg'></img>
            </styleds.socialAppIconContent>
          </styleds.infoImg>
          <styleds.infoText>
            {dataIntroductions?.map((s, index) => {
            return (<Item key={index} data={s}/>);
        })}
          </styleds.infoText>
        </styleds.content>
      </styleds.body>
      <styleds.linkButtonContent>
        <styleds.linkButton bgFill='#d95e5b'>加入會員</styleds.linkButton>
        <styleds.linkButton bgFill='#459ada'>老師留言板</styleds.linkButton>
      </styleds.linkButtonContent>
    </styleds.container>);
});
const styleds = {
    //共用css
    container: styled.div `
    ${fill_vertical_all_center};
    height: auto;
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 40px calc(100% - 112px) 72px;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 40px);
    //display: grid;
    //grid-template-columns: 40% 60%;
    padding: 8px;
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
    margin-left: -4px;
  `,
    //共用css
    content: styled.div `
    ${fill};
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 4px;
  `,
    starIcon: styled.img `
    width: 24px;
  `,
    infoImg: styled.div `
    ${fill_vertical_all_center}
    background-color:#fafafa;
    font-weight: 600;
    gap: 8px;
  `,
    infoText: styled.div `
    ${fill_vertical_cross_center}
    background-color:#fafafa;
  `,
    infoHeader: styled.div `
    ${fill_horizontal_cross_center};
    height: 24px;
    font-weight: 600;
  `,
    infobody: styled.div `
    padding: 4px;
  `,
    personImg: styled.img `
    width: 94%;
    border-radius: 50%;
    border: 2px solid #459ada;
  `,
    linkButtonContent: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    gap: 8px;
    padding: 0 16px;
  `,
    socialAppIconContent: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceAround};
    height: 48px;
  `,
    linkButton: styled.div `
    ${fill_horizontal_all_center};
    width: 50%;
    height: 40px;
    border-radius: 4px;
    background-color: ${props => props.bgFill};
    color: #ffffff;
  `,
};
