import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
export const StockForum_TopQuote = memo(function StockForum_TopQuote() {
    return <styleds.container></styleds.container>;
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #f8f8f8;
  `,
};
