import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const VideoContent = memo(function VideoContent(props) {
    return (<styleds.container>
      <styleds.header>
        <styleds.iconProduct src='stockforum/youtube-icon.png'></styleds.iconProduct>
        <span>{props.channelName}</span>
      </styleds.header>
      <styleds.body>
        <MianItem config={props.videoConfig[0]}/>
        <Divider my='sm' css={css `
            width: 100%;
          `}/>
        <SubItem config={props.videoConfig[1]}/>
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 40px calc(100% - 112px) 72px;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
  `,
    mainItem: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px calc(100% -30px);
    border-radius: 4px;
    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  `,
    item: styled.div `
    display: grid;
    grid-template-columns: 28% 72%;
    border-radius: 4px;
    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
  `,
};
const MianItem = memo(function MianItem(props_) {
    const header = css `
    font-weight: 600;
    grid-row: 1;
    grid-column: 1 / 3;
  `;
    const videoImg = css `
    ${fill_vertical_all_center};
    grid-row: 2;
    grid-column: 1;
    padding: 8px;
  `;
    const body = css `
    ${fill_vertical_cross_center};
    grid-row: 2;
    grid-column: 2;
    padding: 8px;
    font-size: 14px;
  `;
    return (<styleds.mainItem>
      <span css={header}>{props_.config.title}</span>
      <img css={videoImg} src={props_.config.imgSrc}/>
      <span css={body}>{props_.config.text}</span>
    </styleds.mainItem>);
});
const SubItem = memo(function SubItem(props_) {
    const header = css `
    font-weight: 600;
    grid-row: 1;
    grid-column: 1 / 3;
  `;
    const videoImg = css `
    ${fill_vertical_all_center};
    grid-row: 2;
    grid-column: 1;
    padding: 8px;
  `;
    const body = css `
    grid-row: 2;
    grid-column: 2;
    padding: 8px;
    font-size: 14px;
  `;
    return (<styleds.item>
      <span css={header}>{props_.config.title}</span>
      <img css={videoImg} src={props_.config.imgSrc}/>
      <div css={css `
          ${fill_vertical_cross_center};
        `}>
        <span css={body}>{props_.config.text}</span>
      </div>
    </styleds.item>);
});
