import useSWR from 'swr-0-5-6';
// 注意:
// cms會給{meta:[], data:[]}
//使用上 useStockrecords(agent).data.data
//拿股票交易績效
export const useStockrecords = (agentName) => {
    const url = 'https://cms.futures-op.com/api/stock-records?filters%5BcreatedBy%5D%5Bfirstname%5D%5B$eq%5D=' +
        agentName;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        data: res.data,
        isLoading: !res.data && !res.error,
    };
};
//拿期貨交易績效
export const useFuturesrecords = (agentName) => {
    const url = 'https://cms.futures-op.com/api/futures-records?filters%5BcreatedBy%5D%5Bfirstname%5D%5B$eq%5D=' +
        agentName;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        data: res.data,
        isLoading: !res.data && !res.error,
    };
};
//拿分析師相關基本資料
export const useAnalysts = (agentName) => {
    const url = 'https://cms.futures-op.com/api/analysts?filters%5BcreatedBy%5D%5Bfirstname%5D%5B$eq%5D=' +
        agentName;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        data: res.data,
        isLoading: !res.data && !res.error,
    };
};
//拿分析師介紹欄位
export const useIntroduction = (agentName) => {
    const url = 'https://cms.futures-op.com/api/introductions?filters%5BcreatedBy%5D%5Bfirstname%5D%5B$eq%5D=' +
        agentName;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        data: res.data,
        isLoading: !res.data && !res.error,
    };
};
//product description 產品介紹
//----------------------------------------------------------------
const fetcher = async (url) => {
    const res = await fetch(url);
    if (res.status >= 400)
        throw new Error();
    return res.json();
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
