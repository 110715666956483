import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const ProductContent = memo(function ProductContent() {
    return (<styleds.container>
      <styleds.header>
        <styleds.iconProduct src='stockforum/product2-icon.png'></styleds.iconProduct>
        <span>商品專區</span>
        <styleds.loadMore>點我看更多..</styleds.loadMore>
      </styleds.header>
      <styleds.body>
        <styleds.productImage src='stockforum/product-demo.png'></styleds.productImage>
        <styleds.productIntroduce>
          <styleds.introduceTitle>獨家看盤系統</styleds.introduceTitle>
          <styleds.introduceItem>1.免安裝、同時支援多種設備</styleds.introduceItem>
          <styleds.introduceItem>2.每日獨家神秘人選股</styleds.introduceItem>
          <styleds.introduceItem>3.盤中高勝率數據指標</styleds.introduceItem>
          <styleds.introduceItem>4.盤中獨家市場力道數據</styleds.introduceItem>
        </styleds.productIntroduce>
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 40px calc(100% - 112px) 72px;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 8px;
  `,
    productImage: styled.img `
    max-width: 100%;
    border-radius: 4px;
  `,
    productIntroduce: styled.div `
    ${fill_vertical_cross_center};
    padding-left: 16px;
    gap: 4px;
  `,
    introduceTitle: styled.div `
    ${fill_horizontal_cross_center};
    font-weight: 600;
  `,
    introduceItem: styled.div `
    ${fill_horizontal_cross_center};
  `,
    loadMore: styled.div `
    font-size: 12px;
    color: #676767;
    cursor: pointer;
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
    margin-left: -4px;
  `,
};
