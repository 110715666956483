import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { store } from '~/pages/heineken_template/_private/store';
import { component } from '~/utils/component';
import { meCheckHandlerHasMe } from '~/modules/SDK/me/meCheckHandlerHasMe';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { StockForum_Topbar } from './promotion_Topbar';
import { StockForum_TopQuote } from './promotion _TopQuote';
import { StockForum_Footer } from './promotion_Footer';
import { StockForum_Main } from './promotion_main';
import { Fragment } from 'react';
import { chatwoot } from '~/modules/SDK/chatwoot/chatwoot';
import { StockForum_Chart } from './promotion_chart';
import { fr_me } from '../heineken_template/_fr/fr_me';
export const promotion_init = {
    global(templateProps) {
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode('light');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = StockForum_Topbar;
    },
    indexPage(templateProps) {
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode(useThemeStore.getState().theme);
        templateProps.permissions.pageview = meCheckHandlerHasMe;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 64px 1fr 368px 168px;
      grid-template-columns: 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Row3 Row3 Row3'
        'Row4 Row4 Row4'
        'Row5 Row5 Row5';

      ${createIPadCss(css `
        grid-template-rows: 48px 64px 1fr 268px 168px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Row2 Row2 Row2'
          'Row3 Row3 Row3'
          'Row4 Row4 Row4'
          'Row5 Row5 Row5';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 64px 1fr 268px 168px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Row2 Row2 Row2'
          'Row3 Row3 Row3'
          'Row4 Row4 Row4'
          'Row5 Row5 Row5';
      `)}
    `;
        //templateProps.layout.login = SgxLoginPage
        // templateProps.layout.Drawer1 = Sgx_SidePane1
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = StockForum_Topbar;
        templateProps.layout.Row2 = StockForum_TopQuote;
        templateProps.layout.Row3 = StockForum_Main;
        templateProps.layout.Row4 = StockForum_Chart;
        templateProps.layout.Row5 = StockForum_Footer;
        templateProps.layout.Providers = component(props => {
            return (<Fragment>
          <chatwoot.Widget config={chatwoot.config['futuresai@web']}/>
          {props.children}
        </Fragment>);
        });
        templateProps.hooks.add(useSignalrStart2_0);
        templateProps.hooks.add(fr_me.useInstall);
    },
    ['product/index.page'](templateProps) {
        promotion_init.global(templateProps);
        templateProps.permissions.pageview = meCheckHandlerHasMe;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 168px;
      grid-template-columns: 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Row5 Row5 Row5';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 168px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Row2 Row2 Row2'
          'Row5 Row5 Row5';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 168px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Row2 Row2 Row2'
          'Row5 Row5 Row5';
      `)}
    `;
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = StockForum_Topbar;
        templateProps.layout.Row2 = <div>123</div>;
        templateProps.layout.Row5 = StockForum_Footer;
        templateProps.layout.Providers = component(props => {
            return (<Fragment>
          <chatwoot.Widget config={chatwoot.config['futuresai@web']}/>
          {props.children}
        </Fragment>);
        });
    },
};
