import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { fr_liteChart } from '../heineken_template/_fr/fr_liteChart';
import dayAPI from '~/utils/dayAPI';
import { css } from '@emotion/react';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
const symbols = ['TSEA', 'OTCA', 'TXAM-1', 'TSE13', 'TSE17'];
//---------------------------------------------
fr_liteChart.themes.light.candlestickSeries = {
    upColor: '#ff4400',
    downColor: '#00aa00',
    wickUpColor: '#252525',
    wickDownColor: '#252525',
    borderVisible: false,
};
fr_liteChart.themes.dark.lineSeries = {
    color: '#d1d1d1',
};
//---------------------------------------------
export const StockForum_Chart = memo(function StockForum_Chart() {
    const [symbol, setSymbol] = useState('TSEA');
    useEffect(() => {
        signalrStore2.addQuote(symbols);
        return () => {
            signalrStore2.removeQuote(symbols);
        };
    }, [JSON.stringify(symbols)]);
    const value = signalrHooks2.useQuotes(symbols);
    return (<styleds.container>
      <styleds.grid>
        <styleds.listContent>
          <span>觀察清單</span>
          <div css={css `
              ${fill_vertical_cross_center};
              gap: 2px;
            `}>
            {value.map(s => {
            return (<QuoteBody key={s.symbol} chartSymbol={symbol} value={s} onClick={() => setSymbol(s.symbol)}/>);
        })}
          </div>
        </styleds.listContent>
        <styleds.chartContent>
          <fr_liteChart.Chart symbol={symbol} interval={'5'} from={dayAPI().subtract(14, 'days')}>
            <fr_liteChart.Kbars />
          </fr_liteChart.Chart>
        </styleds.chartContent>
      </styleds.grid>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    background-color: #f8f8f8;
    gap: 16px;
    padding: 0 120px;
  `,
    grid: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 336px calc(100% - 352px);
    background-color: #f0f0f0;
    padding: 8px;
    gap: 16px;
  `,
    listContent: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    chartContent: styled.div `
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
};
//報價本身元件
const QuoteBody = memo(function QuoteBody(props) {
    const quoteChanges = processQuoteToReadable(props.value);
    const changeSymbol = quoteChanges.closeChange > 0 ? '+' : null;
    const selected = props.chartSymbol === props.value.symbol;
    const fill = quoteChanges.closeChange > 0 ? '#ff0000' : quoteChanges.closeChange < 0 ? '#00aa00' : '#252525';
    //css
    const container = css `
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-columns: 50% 50%;
    width: 100%;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: ${selected === true ? '#f5f5f5' : 'transparent'};
    cursor: pointer;
    &:hover {
      border: 1px solid #e1e1e1;
    }
  `;
    const closeValue = css `
    display: flex;
    justify-content: end;
    color: ${fill};
    font-weight: bold;
  `;
    const changeValue = css `
    display: flex;
    justify-content: end;
    gap: 8px;
    font-size: 13px;
    color: ${fill};
  `;
    return (<div onClick={event => {
            props.onClick?.();
        }} css={container}>
      <span>{props.value?.symbol}</span>
      <span css={closeValue}>{props.value?.close}</span>
      <SymbolName symbol={props.value?.symbol}/>
      <span css={changeValue}>
        <span>
          {changeSymbol}
          {quoteChanges.closeChange}
        </span>
        <span>
          {changeSymbol}
          {quoteChanges.closeChangePercent}%
        </span>
      </span>
    </div>);
});
